<template>
  <div>
    <component
      :is="computedComponentName"
      v-model="shouldShowModal"
      :close-on-content-click="false"
    >
      <template v-slot:activator="{ on, attrs }">
        <div
          class="d-flex align-center justify-between filter-title"
          :class="{ 'font-weight-bold': isFilterApplied }"
          v-on="on"
          v-bind="attrs"
          >
          <div class="d-flex align-center flex-grow-1">
            <v-icon left>
              search
            </v-icon>
            Interest
          </div>
          <v-icon>
            expand_more
          </v-icon>
        </div>
      </template>
      <v-card>
        <v-card-title class="d-md-none">
          Interest
        </v-card-title>

        <v-card-text
          class="pt-md-3"
          :class="{ 'black--text': isThemeLight }"
        >
          <v-row>
            <!-- Audience Section -->
            <v-col
              v-for="kind in computedKinds"
              :key="kind"
              cols="12"
              :md="computedKinds.length === 1 ? 12 : 6"
            >
              <div class="mb-1 d-flex">
                <div class="d-flex align-center flex-grow-1">
                  <v-icon
                    :class="{ 'black--text': isThemeLight }"
                    class="mr-1"
                    left
                  >
                    {{ kind === 'audience' ? 'people' : 'volume_down' }}
                  </v-icon>

                  {{ computedKinds.length > 1 ? capitalizeString(kind) : 'Interest' }}
                </div>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      :color="isThemeLight ? 'black' : null"
                      v-bind="attrs"
                      v-on="on"
                    >
                      info
                    </v-icon>
                  </template>

                  <span>
                    {{ tooltipTexts[kind] }}
                  </span>
                </v-tooltip>
              </div>

              <v-autocomplete
                v-model="form[kind]"
                :items="searchItems"
                label="Interests"
                dense
                eager
                outlined
                :append-icon="null"
                return-object
                hide-details
                item-text="name"
                item-value="id"
                @change="submitForm(kind)"
              >
                <template v-slot:no-data>
                  <div class="text-center py-2">
                    No results found.
                  </div>
                </template>
              </v-autocomplete>

              <div v-if="appliedFiltersFor[kind].length" class="mt-3">
                <div
                  v-for="item in appliedFiltersFor[kind]"
                  :key="item.id"
                >
                  <div class="d-flex justify-space-between align-center pb-1 pt-3">
                    <div class="d-flex align-center">
                      <v-btn small icon @click="removeFilterItem(item)">
                        <v-icon small>
                          clear
                        </v-icon>
                      </v-btn>

                      {{ item.data.inputs.name }}
                    </div>

                    <weight-selector
                      v-if="kind === 'audience'"
                      v-model="weightInput[item.timestamp]"
                      @change="updateFilterItem(item)"
                    />
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </component>
  </div>
</template>

<script>
// Import helper function
import dataHelper from "@/helpers/dataHelper"

// Import children components
const WeightSelector = () => import(/* webpackChunkName: "weight-selector" */ "@/blocks/common/selectors/WeightSelector.vue")

// Used throughout the code
const filterName = "interest"

// Define default form object
const originalForm = () => ({
  audience: null,
  influencer: null
})

// Export the SFC
export default {
  // Name of the component
  name: "FilterInterest",

  // Register children components
  components: {
    WeightSelector
  },

  // Accept incoming data from parent
  props: {
    appliedFilters: {
      type: Array,
      required: false,
      default: () => ([])
    },

    isFilterApplied: {
      type: Boolean,
      required: true
    },

    showAudience: {
      type: Boolean,
      required: false,
      default: true
    },

    useLabel: {
      type: Boolean,
      required: false,
      default: true
    }
  },

  // Define local data variables
  data: () => ({
    form: originalForm(),

    shouldShowModal: false,
    searchQuery: {
      audience: "",
      influencer: ""
    },

    // The key would be the symbol for the applied filter in VueX state
    weightInput: {},

    interests: [],

    tooltipTexts: {
      audience: "Identify influencers by their audience interests. We determine interest of audience by analyzing posts for caption text, mentions, hashtags and location tags. You can add several interests and specify minimum percentage for each interest, your search results will be refined with influencers that have audience mentioning ANY of the specified interests.",
      influencer: "Identify influencers by their interests. We determine  interest of influencers by analyzing posts for caption text, mentions, hashtags and location tags. You can add several interests and your search results will be refined with influencers that have ANY of the specified interests."
    }
  }),

  // Define readonly data variables
  computed: {
    /**
     * Show the form as dialog on mobile
     *
     * @returns {Boolean}
     */
    computedComponentName() {
      return this.isDesktopDevice ? "v-menu" : "v-dialog"
    },

    /**
     * Get if the filters were applied
     *
     * @returns {Object}
     */
    appliedFiltersFor() {
      return {
        audience: this.appliedFilters.filter((item) => item.data.kind === "audience"),
        influencer: this.appliedFilters.filter((item) => item.data.kind === "influencer")
      }
    },

    /**
     *
     */
    computedKinds() {
      if (this.showAudience) {
        return ['audience', 'influencer']
      }
      else {
        return ['influencer']
      }
    },

    /**
     * Get mapped interest items
     *
     * @returns {Array}
     */
    searchItems() {
      return this.interests.map((item) => ({ ...item, weight: 0.05 }))
    }
  },

  validations: {
    form: {
      audience: {},
      influencer: {}
    }
  },

  methods: {
    /**
     * Takes an item and generates the text based on kind and weight
     */
    computeFilterText({ kind, name, weight }) {
      return `${this.useLabel ? capitalizeString(kind) + ' ' : ''}Interest: ${name}` + (kind === 'audience' ? ` | >${weight * 100}%` : '')
    },

    /**
     * Push the data from either of the inputs to the filters array in Vuex Store
     *
     * @param {String} kind | Either "audience" or "influencer"
     */
    async submitForm(kind) {
      await this.$v.form[kind].$touch()

      if (this.$v.form[kind].$anyError) return

      this.$emit("submit", {
        type: filterName,
        data: {
          color: "pink lighten-5",
          icon: "flag",
          iconColor: "pink",
          kind,
          text: this.computeFilterText({ kind, name: this.form[kind].name, weight: this.form[kind].weight }),
          inputs: {
            ...this.form[kind]
          }
        }
      })

      // reset the search query input
      this.searchQuery[kind] = ""
      // reset the form input to allow more values
      this.form[kind] = null
      // finally hide the modal
      this.shouldShowModal = false
    },

    /**
     * Update the value of already existing filter
     * Most probably it'd just be the weight attribute
     */
    updateFilterItem(item) {
      // Update the weight attribute
      item.data.inputs.weight = this.weightInput[item.timestamp]
      // Update the text value for filter
      item.data.text = this.computeFilterText({
        kind: item.data.kind,
        name: item.data.inputs.name,
        weight: item.data.inputs.weight
      })

      this.$emit("update", item)
    },

    /**
     * Delete the entry from vuex store
     *
     * @param {Object} item
     */
    removeFilterItem(item) {
      this.$emit("remove", item)
    },
  },

  /**
   * As soon as the component data is ready
   *
   * @returns {void}
   */
  async created() {
    // Fetch and store interests in the store
    this.interests = await dataHelper.loadData("interests.json")
  },

  /**
   * Before the component is destroyed
   *
   * @returns {void}
   */
  beforeDestroy() {
    // Unload the interests from the store
    dataHelper.unloadData("interests.json")
  }
}
</script>
